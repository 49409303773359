import type { RecursivePartial } from '#types/utils'
import type { CheckoutConfig } from '#types/config/pages/checkout'

export default {
  contactFieldsOrder: ['email', 'phone'],
  showCheckoutFooter: true,
  loyaltyType: [
    'LOYALTY_POINTS',
  ],
  hideLoyaltyDivider: false,
} satisfies RecursivePartial<CheckoutConfig>
